import BaseControl from './base-control'

import Layout from './templates/site-control.tpl'

import './style/site-control.scss'

class SiteControl extends BaseControl {
  constructor (controller) {
    super(controller, () => Layout, controller.model.pages, 'site')
    this.lazyImageSelector = ''
  }
}
export default SiteControl
